import React from 'react';

import ProductUpdatePage from '../../components/pages/resources/ProductUpdatePage';

import './index.css';

export default (props) => {
  const { pageContext } = props;

  const { resource } = pageContext;

  return (
    <div className="template product-update-page">
      <ProductUpdatePage productUpdate={resource} />
    </div>
  );
};
