import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import App from '../../../layout/App';

import Article from '../../../utils/Article';

import './index.css';

export class ProductUpdatePage extends Component {
  static propTypes = {
    productUpdate: PropTypes.shape({
      title: PropTypes.string.isRequired,
      shortDescription: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
      authorTitle: PropTypes.string.isRequired,
      authorCompany: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      videoUrl: PropTypes.string.isRequired,
    }),
  };

  render() {
    const {
      title,
      shortDescription,
      date,
      author,
      authorTitle,
      authorCompany,
      content,
      videoUrl,
    } = this.props.productUpdate;

    return (
      <App>
        <div className="component product-update-page">
          <Helmet title={`${title} – ThinkReservations`}>
            <meta name="description" content={shortDescription} />
          </Helmet>

          <Article
            title={title}
            date={date}
            author={author}
            authorTitle={authorTitle}
            authorCompany={authorCompany}
            content={content}
            videoUrl={videoUrl}
          />
        </div>
      </App>
    );
  }
}

export default ProductUpdatePage;
